<template>
	<v-sheet flat>
		<v-tabs v-model="tab">
			<v-tab>viz</v-tab>
			<v-tab>html</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tab">
			<v-tab-item>
				<vue-editor
					:value="value"
					@input="(value) => $emit('input', value)"
				></vue-editor>		
			</v-tab-item>
			
			<v-tab-item>
				<v-textarea :value="value"
					@blur="(event) => $emit('input', event.target.value)">

				</v-textarea>
			</v-tab-item>
		</v-tabs-items>

	</v-sheet>

</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
	name: "MwEditor",
	props: {
		value: { type: String },
	},
	data: () => {
		return {
			tab: 0
		};
	},
	components: {
		VueEditor,
	},
};
</script>
